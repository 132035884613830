<template>
    <div>
        <v-dialog
            v-model="visible"
            :fullscreen="fullscreen"
            :width="dialogWidth"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        >{{ login }} Summary</v-toolbar-title
                    >

                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                class="mr-2"
                                style="display: block; float: right"
                                @click="startTutorial"
                                >mdi-school</v-icon
                            >
                        </template>
                        <span>Click to start tutorial</span>
                    </v-tooltip>
                    <v-btn
                        v-if="!VUETIFY_BREAKPOINT.mobile"
                        color="white"
                        icon
                        @click="fullscreen = !fullscreen"
                    >
                        <v-icon>mdi-arrow-expand</v-icon>
                    </v-btn>
                    <v-btn color="white" icon @click="visible = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-tabs>
                    <v-tab v-on:click="changeView('trades')" v-if="showTrades"
                        >Trades</v-tab
                    >
                    <v-tab v-on:click="changeView('info')" v-if="showSummary"
                        >Info</v-tab
                    >
                    <v-tab
                        v-on:click="changeView('snapshot')"
                        v-if="showLoginSnapshot"
                    >
                        Login Snapshot</v-tab
                    >
                </v-tabs>

                <v-row class="pa-4 pb-0" v-if="loadSummary">
                    <v-col
                        cols="2"
                        v-for="(value, name, index) in loginSummary"
                        :key="index"
                    >
                        <v-card class="mx-auto" max-width="344">
                            <v-card-text>
                                <div>
                                    {{
                                        name === "total_volume_usd"
                                            ? "TOTAL VOLUME(USD)"
                                            : name
                                                  .toUpperCase()
                                                  .replace("_", " ")
                                    }}
                                </div>
                                <p class="text text--primary">{{ value }}</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pa-4 pt-0 pb-0" v-if="loadSummary">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title> Group change history </v-card-title>
                            <v-data-table
                                :headers="loginGroupChangeHeader"
                                :items="loginGroupChangeData"
                                :header-props="{ sortIcon: null }"
                                :mobile-breakpoint="0"
                                id="loginGroupChangeHistory"
                            >
                                <template v-slot:item.server_id="{ item }">
                                    {{ toServerName(item.server_id) }}
                                </template>
                                <template v-slot:item.created_at="{ item }">
                                    {{ toServerTime(item.created_at) }}
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pa-4 pt-0" v-if="loadSummary && showChart">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>Statistic Chart</v-card-title>
                            <LoginHistoryChart
                                :dom="'alert-' + login"
                                :loginValue="selectedLogin"
                                id="loginStatistic"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <LoginTradesTable
                    v-if="loadTrades"
                    :loginValue="fullLogin"
                    :server="server"
                    :symbol="symbol"
                />
                <v-card-actions />
                <SnapshotTab
                    v-if="loadSnapshot"
                    target="login"
                    :book="book"
                    :server="server"
                    :login="login"
                    :symbol="symbol"
                />
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import LoginHistoryChart from "./ComponentLoginHistory";
import LoginTradesTable from "./ComponentLoginTrades";
import SnapshotTab from "@views/home/ComponentSnapshotTab";
import { mapState, mapActions, mapMutations } from "vuex";
import dayjs from "dayjs";
import introJs from "intro.js";
import { TutorialConstant } from "@assets/tutorialConstant";

export default {
    components: { LoginHistoryChart, LoginTradesTable, SnapshotTab },
    mixins: [],
    props: {
        fullLogin: null,
        login: null,
        server: null,
        symbol: null,
        book: null
    },
    data() {
        return {
            tutorialConstant: TutorialConstant,
            tab: "statistic",
            visible: false,
            fullscreen: false,
            selectedLogin: this.login,
            loadSummary: false,
            loadTrades: false,
            loadSnapshot: false,
            showChart: false,
            showSummary: false,
            showTrades: false,
            showLoginSnapshot: true,
            dialogWidth: 1600
        };
    },
    watch: {
        visible(nv) {
            if (nv) {
                this.UPDATE_CLOSE_TRADE_DATA([]);
                this.UPDATE_OPEN_TRADE_DATA([]);
            }
        },
        "VUETIFY_BREAKPOINT.mobile": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.fullscreen = true;
                }
            }
        },
        homeLoginTradesTutorial(nv) {
            if (nv) {
                this.getLoginTradesTutorial()
                    .oncomplete(() =>
                        this.updateLoginTradesTutorialAction(false)
                    )
                    .onexit(() => this.updateLoginTradesTutorialAction(false))
                    .start();
            }
        },
        homeLoginInfoTutorial(nv) {
            if (nv) {
                this.getLoginInfoTutorial()
                    .oncomplete(() => this.updateLoginInfoTutorialAction(false))
                    .onexit(() => this.updateLoginInfoTutorialAction(false))
                    .start();
            }
        },
        homeLoginSnapshotTutorial(nv) {
            if (nv) {
                this.getLoginSnapshotTutorial()
                    .oncomplete(() =>
                        this.updateLoginSnapshotTutorialAction(false)
                    )
                    .onexit(() => this.updateLoginSnapshotTutorialAction(false))
                    .start();
            }
        }
    },
    computed: {
        ...mapState("LoginSummary", [
            "loginSummary",
            "loginGroupChangeHeader",
            "loginGroupChangeData"
        ]),
        ...mapState("SettingsServer", ["serverData", "serverHeader"]),
        ...mapState("Home", [
            "homeLoginTradesTutorial",
            "homeLoginInfoTutorial",
            "homeLoginSnapshotTutorial"
        ])
    },
    methods: {
        ...mapMutations("LoginSummary", [
            "UPDATE_CLOSE_TRADE_DATA",
            "UPDATE_OPEN_TRADE_DATA"
        ]),
        ...mapActions("LoginSummary", [
            "getLoginSummaryAction",
            "getLoginGroupChangeAction",
            "getLoginCloseTradesAction",
            "getLoginOpenTradesAction"
        ]),
        ...mapActions("Home", [
            "updateLoginTradesTutorialAction",
            "updateLoginInfoTutorialAction",
            "updateLoginSnapshotTutorialAction"
        ]),
        startTutorial() {
            if (this.tab == "trades") {
                this.updateLoginTradesTutorialAction(true);
            }
            if (this.tab == "info") {
                this.updateLoginInfoTutorialAction(true);
            }
            if (this.tab == "snapshot") {
                this.updateLoginSnapshotTutorialAction(true);
            }
        },
        getLoginTradesTutorial() {
            console.log("trades");
            return introJs().setOptions({
                steps: [
                    {
                        element: document.getElementById("loginTradesDesc"),
                        title: this.tutorialConstant.loginTradesDialog.loginTradesDesc.title,
                        intro: this.tutorialConstant.loginTradesDialog.loginTradesDesc.intro
                    },
                    {
                        element: document.getElementById("loginTradesRefresh"),
                        title: this.tutorialConstant.loginTradesDialog.loginTradesRefresh.title,
                        intro: this.tutorialConstant.loginTradesDialog.loginTradesRefresh.intro
                    },
                    {
                        element: document.getElementById(
                            "loginTradesExpansion"
                        ),
                        title: this.tutorialConstant.loginTradesDialog.loginTradesExpansion.title,
                        intro: this.tutorialConstant.loginTradesDialog.loginTradesExpansion.intro
                    },
                    {
                        element: document.getElementById(
                            "loginTradesCalculationReference"
                        ),
                        title: this.tutorialConstant.loginTradesDialog.loginTradesCalculationReference.title,
                        intro: this.tutorialConstant.loginTradesDialog.loginTradesCalculationReference.intro
                    },
                    {
                        element: document.getElementById("loginTradesDetail"),
                        title: this.tutorialConstant.loginTradesDialog.loginTradesDetail.title,
                        intro: this.tutorialConstant.loginTradesDialog.loginTradesDetail.intro
                    }
                ]
            });
        },
        getLoginInfoTutorial() {
            return introJs().setOptions({
                steps: [
                    {
                        element: document.getElementById(
                            "loginGroupChangeHistory"
                        ),
                        title: this.tutorialConstant.loginInfoDialog.loginGroupChangeHistory.title,
                        intro: this.tutorialConstant.loginInfoDialog.loginGroupChangeHistory.intro
                    },
                    {
                        element: document.getElementById("loginStatistic"),
                        title: this.tutorialConstant.loginInfoDialog.loginStatistic.title,
                        intro: this.tutorialConstant.loginInfoDialog.loginStatistic.intro
                    }
                ]
            });
        },
        getLoginSnapshotTutorial() {
            return introJs().setOptions({
                steps: [
                    {
                        element: document.getElementById(
                            "symbolTabTimeSelection"
                        ),
                        title: this.tutorialConstant.homeSymbolSnapshotDialog.symbolTabTimeSelection.title,
                        intro: this.tutorialConstant.homeSymbolSnapshotDialog.symbolTabTimeSelection.intro
                    },
                    {
                        element: document.getElementById(
                            "symbolTabSnapshotTable"
                        ),
                        title: this.tutorialConstant.homeSymbolSnapshotDialog.symbolTabSnapshotTable.title,
                        intro: this.tutorialConstant.homeSymbolSnapshotDialog.symbolTabSnapshotTable.intro
                    }
                ]
            });
        },

        /**
         * change tab event
         * @param   {[type]}  tab  [tab description]
         * @return  {[type]}       [return description]
         */
        changeView(tab) {
            this.loadSummary = false;
            this.loadTrades = false;
            this.loadSnapshot = false;
            if (tab === "info") {
                this.loadSummary = true;
                this.dialogWidth = 1600;
            }
            if (tab === "trades") {
                this.loadTrades = true;
                this.dialogWidth = 1600;
            }
            if (tab === "snapshot") {
                this.loadSnapshot = true;
                this.dialogWidth = 1100;
            }
            this.tab = tab;
        },
        /**
         * Initial login summary
         * @return  {[type]}  [return description]
         */
        loadInfo() {
            const params = { login: this.login };
            if (this.showSummary) {
                this.getLoginSummaryAction(params);
            }
            if (this.showChart) {
                this.getLoginGroupChangeAction(params);
            }
            if (this.showTrades) {
                const tradesParams = {
                    login: this.fullLogin,
                    symbol: this.symbol
                };
                this.getLoginCloseTradesAction({
                    ...tradesParams,
                    liveOrHis: false
                });
                this.getLoginOpenTradesAction({
                    ...tradesParams,
                    liveOrHis: true
                });
            }

            if (this.showTrades) {
                this.loadTrades = true;
                this.tab = "trades";
            } else if (this.showSummary) {
                this.loadSummary = true;
                this.tab = "info";
            } else {
            }
        },
        toServerTime(ts) {
            const serverTime = dayjs(ts)
                .add(2, "hours")
                .format("YYYY-MM-DD HH:mm:ss");
            return serverTime;
        },
        toServerName(serverId) {
            const serverName = this.serverData.find(
                (item) => item.id === serverId
            ).server_name;
            return serverName;
        }
    },
    mounted() {
        this.visible = true;
        this.loadInfo();
    },
    created() {
        if (this.$route.path == "/ib-monitor") {
            const userFunction = JSON.parse(localStorage.getItem("permission"))
                ?.frontPermission?.ibMonitor?.function;
            if (userFunction.includes("ibLoginSummaryInfo")) {
                this.showSummary = true;
            }
            if (userFunction.includes("ibLoginSummaryStatistic")) {
                this.showChart = true;
            }
            if (userFunction.includes("ibLoginSummaryTrades")) {
                this.showTrades = true;
            }
        } else {
            const userFunction = JSON.parse(localStorage.getItem("permission"))
                ?.frontPermission?.book?.function;
            if (userFunction.includes("loginSummaryInfo")) {
                this.showSummary = true;
            }
            if (userFunction.includes("loginSummaryStatistic")) {
                this.showChart = true;
            }
            if (userFunction.includes("loginSummaryTrades")) {
                this.showTrades = true;
            }
            if (userFunction.includes("loginSnapshot") && !this.$route.path.includes("/alert")) {
                this.showLoginSnapshot = true;
            }
        }
    }
};
</script>
<style scoped>
div >>> .v-dialog {
    overflow-x: hidden;
}
</style>
